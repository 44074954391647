const PaymentMethod = Object.freeze({
    alipay: 'alipay',
    BANK_TRANSFER: 'BANK_TRANSFER',
    CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
    CREDIT_CARD: 'CREDIT_CARD',
    CRYPTO_CREDIT: 'CRYPTO_CREDIT',
    directEbanking: 'directEbanking',
    GIFT_CERTIFICATE: 'GIFT_CERTIFICATE',
    ideal: 'ideal',
    klarna: 'klarna',
    klarna_account: 'klarna_account',
    MOCK_FREE: 'MOCK_FREE',
    paypal: 'paypal',
    PayPalExpress: 'PayPalExpress',
    VOUCHER: 'VOUCHER',
    WeChat: 'WeChat',
    WeChatPay: 'WeChatPay',
    MiraklYOOX: 'Mirakl Yoox',
    MiraklBESTSEC: 'Mirakl Bestsecret',
    TRADEBYTE_BRBC: 'TB Breuninger',
    TRADEBYTE_OCDE: 'TB OutletCity',
    TRADEBYTE_INDE: 'TB Intersport',
});

export {PaymentMethod}